type CaseData = {
  case_id: string;
  case_title: string;
  case_description: string;
  case_status: "open" | "closed" | "pending";
  created_at: string;
  updated_at: string;
  access: { user_id: string }[];
};

const caseData: CaseData[] = [
  {
    case_id: "MLC001",
    case_title: "NHS Foundation Trust vs EB & Ors",
    case_description:
      "A 45-year-old male alleges misdiagnosis of a heart condition, resulting in unnecessary surgery and complications.",
    case_status: "pending",
    created_at: "2023-06-15T10:30:00Z",
    updated_at: "2023-12-20T14:45:00Z",
    access: [{ user_id: "U12345" }, { user_id: "U67890" }],
  },
  {
    case_id: "MLC002",
    case_title: "Private Health services vs JS",
    case_description:
      "A 38-year-old female claims surgical errors during a procedure led to permanent nerve damage.",
    case_status: "closed",
    created_at: "2022-11-05T09:00:00Z",
    updated_at: "2023-08-15T16:20:00Z",
    access: [{ user_id: "U54321" }, { user_id: "U98765" }],
  },
  {
    case_id: "MLC003",
    case_title: "NHS Foundation Trust vs JH",
    case_description:
      "A 52-year-old male alleges failure to diagnose cancer in its early stages, leading to advanced disease progression.",
    case_status: "open",
    created_at: "2023-03-12T08:45:00Z",
    updated_at: "2024-01-10T11:15:00Z",
    access: [{ user_id: "U13579" }],
  },
  {
    case_id: "MLC004",
    case_title: "Doe vs. NHS",
    case_description:
      "A 60-year-old female suffered cardiac arrest during surgery due to improper anesthesia administration.",
    case_status: "closed",
    created_at: "2021-09-27T07:30:00Z",
    updated_at: "2022-07-01T10:00:00Z",
    access: [{ user_id: "U24680" }, { user_id: "U11223" }],
  },
];
export default caseData;
export type { CaseData };

// React imports
import React from "react";
import { Outlet } from "react-router-dom";

// Material-UI imports
import styled from "@emotion/styled";
import { Box, CssBaseline } from "@mui/material";

// Custom components
import GlobalStyle from "../components/GlobalStyle";
import Footer from "../components/footer/NavigationFooter";

const Root = styled(Box)`
  height: 100vh;

  display: flex;
  flex-direction: column;
`;
const ContentContainer = styled(Box)`
  height: calc(100vh - 60px);
  background: ${(props) => props.theme.palette.background.default};
`;

const AppContent = styled(Box)`
  background: ${(props) => props.theme.palette.background.default};
`;

// const Footer = styled(Box)`
//   flex-shrink: 0;
//   height: 64px;
//   background-color: blue;
// `;

interface DashboardType {
  children?: React.ReactNode;
}

const Sidelight: React.FC<DashboardType> = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <ContentContainer>
        <AppContent>
          {children}
          <Outlet />
        </AppContent>
      </ContentContainer>
      <Footer />
      {/* <Footer /> */}
    </Root>
  );
};

export default Sidelight;

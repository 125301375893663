// react imports
import React from "react";
import { useNavigate } from "react-router-dom";

// Redux imports
import { useDispatch } from "react-redux";
import { setSelectedCase } from "../../redux/slices/selectedCaseSlice";

// MUI imports

import {
  DataGridPro,
  //   gridClasses,
  GridEventListener,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";

// Project imports

//data imports
import caseListData from "../../mockData/sidelight/caseData";

function CaseTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    navigate(`/case/${params.id}`);
    dispatch(setSelectedCase(params.id.toString()));
  };

  return (
    <React.Fragment>
      <DataGridPro
        getRowId={(row) => row.case_id} // Set the getRowId to the case_id
        onRowClick={handleRowClick}
        // getRowHeight={() => "auto"}
        disableColumnSelector // Disable the column selector
        disableDensitySelector // Disable the density selector
        checkboxSelection
        disableRowSelectionOnClick // Disable row selection on click
        slots={{ toolbar: GridToolbar }} // Set the toolbar slot to GridToolba
        slotProps={{
          toolbar: {
            showQuickFilter: true, // Show the quick filter in the toolbar
          },
        }}
        sx={{
          pt: 1,
          border: "none",
          backgroundColor: "white",

          //   [`& .${gridClasses.cell}`]: {
          //     pt: 3,
          //     pb: 2,
          //   },
        }}
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          }, // Set the pinned columns
          sorting: { sortModel: [{ field: "created_at", sort: "asc" }] }, // Set the sorting column
          density: "comfortable", // Set the density to comfortable
        }}
        rows={caseListData} // Set the rows of the DataGridPro to the timeline data
        columns={[
          // Define the columns for the DataGridPro
          {
            field: "case_id",
            headerName: "Case ID",
            width: 100,
          },
          {
            field: "case_title",
            headerName: "Title",
            flex: 1,
            minWidth: 150,
          },
          {
            field: "case_description",
            headerName: "Description",
            flex: 2,
            minWidth: 150,
          },
          {
            field: "case_status",
            headerName: "Status",
            width: 120,
          },
          {
            field: "created_at",
            headerName: "Date Created",
            width: 150,
            type: "date",
            valueFormatter: (params) => {
              return params ? new Date(params).toLocaleDateString() : "N/A";
            },
          },
        ]}
      />
    </React.Fragment>
  );
}

export default CaseTable;

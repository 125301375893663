import React from "react";
import styled from "@emotion/styled";
// import { NavLink } from "react-router-dom";

import { Drawer as MuiDrawer, ListItemButton } from "@mui/material";

import { SidebarItemsType } from "../../types/sidebar";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import SidebarLogo from "../../theme/SidebarLogo";
// import ThemeDrawerControl from "../themeControl/ThemeDrawerControl";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h3.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;
  letter-spacing: -1.5px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  showFooter?: boolean;
  children?: React.ReactNode;
};

const Sidebar: React.FC<SidebarProps> = ({
  children,
  items,
  showFooter = true,
  ...rest
}) => {
  let theme = localStorage.getItem("theme") || "";
  console.log("children", children);

  return (
    <Drawer variant="permanent" {...rest}>
      {/* <ThemeDrawerControl> */}
      <Brand>
        <SidebarLogo theme={theme} />
      </Brand>
      {/* </ThemeDrawerControl> */}
      <SidebarNav items={items} />
      {children}
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;

import * as React from "react";

import { Box } from "@mui/material";
import styled from "@emotion/styled";
import logo from "../vendor/logo-main-transparent.svg";

const LogoContainer = styled(Box)`
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  padding-left: 15px;
  padding-top: 4px;
  align-items: center;
`;
const Torch = styled(Box)`
  width: 21px;
  height: 15px;
`;

const Beam = styled(Box)`
  height: 17px;
  width: 0;

  background: linear-gradient(90deg, #ffffffbb, #ffffff00);

  animation: beam 0.4s 0.5s forwards;
  @keyframes beam {
    from {
      width: 0;
    }
    to {
      width: 9%;
    }
  }
`;
const LightRim = styled(Box)`
  height: 17px;
  width: 2px;
  margin-left: 2px;
  background: #fff;
  border-radius: 0.4px 0 0 0.4px;
  opacity: 0;

  animation: rim 0.2s 0.5s forwards;

  @keyframes rim {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
const NonBeamContainer = styled(Box)`
  height: 40px;
  width: 115px;
  position: absolute;
  right: 25px;
  top: 5.5px;
`;

export default function SidelightFooterLogo() {
  return (
    <>
      <LogoContainer>
        <Torch>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 21">
            <g fill="#55C1D0" fillRule="evenodd" transform="translate(0 -.077)">
              <rect width="1.983" height="10.819" y="5.167" rx=".992" />
              <rect
                width="1.983"
                height="10.869"
                x="5.47"
                y="5.167"
                rx=".992"
              />
              <rect
                width="1.983"
                height="10.869"
                x="10.968"
                y="5.167"
                rx=".992"
              />
              <rect
                width="1.983"
                height="10.869"
                x="16.465"
                y="5.117"
                rx=".992"
              />
              <rect
                width="1.983"
                height="15.583"
                x="21.981"
                y="2.745"
                rx=".992"
              />
              <rect width="1.983" height="21.154" x="27.441" rx=".992" />
            </g>
          </svg>
        </Torch>
        <LightRim />
        <Beam />
        <NonBeamContainer>
          <img src={logo} alt="Sidelight logo" />
        </NonBeamContainer>
      </LogoContainer>
    </>
  );
}

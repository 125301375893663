// React imports
import React from "react";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { setSelectedCase } from "../../redux/slices/selectedCaseSlice";

// API imports - !fake data!
import caseListData from "../../mockData/sidelight/caseData";
import { CaseData } from "../../mockData/sidelight/caseData";

// MUI imports
import { Autocomplete, TextField } from "@mui/material";

function CaseSelector() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //Get the selected case from the Redux store
  const selectedCaseRedux = String(
    useSelector((state: RootState) => state.caseSelection.selectedItem)
  );
  const selectedCaseParams = useParams<{ id: string }>().id;

  var selectedCase = selectedCaseRedux; //default to Redux value

  if (selectedCaseParams) {
    dispatch(setSelectedCase(selectedCaseParams)); //update Redux value
    selectedCase = selectedCaseParams; //update selectedCase to the URL value
  }

  // !fake data!
  const caseDetails = caseListData.find((c) => c.case_id === selectedCase);

  const [caseOption, setCaseOption] = useState<CaseData | null>(
    caseDetails || null
  );
  console.log("caseOption: ", caseOption);

  const handleSelection = (event: any, newValue: CaseData | null) => {
    setCaseOption(newValue);
    dispatch(setSelectedCase(newValue ? newValue.case_id : ""));
    if (selectedCaseParams) {
      navigate(`/case/${newValue?.case_id}`);
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        id="case-selector"
        value={caseOption}
        options={caseListData}
        onChange={handleSelection}
        getOptionLabel={(option) => option.case_title}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ mt: 1 }}
            size="small"
            label="Case"
            variant="outlined"
          />
        )}
      />
    </React.Fragment>
  );
}

export default CaseSelector;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiConfig, auth0Config } from "../config";

const getToken = async () => {
  const key = `@@auth0spajs@@::${auth0Config.clientId}::${auth0Config.apiAudience}::`;
  for (let i = 0; i < localStorage.length; i++) {
    const k = localStorage.key(i);
    if (k && k.startsWith(key)) {
      const tokenObject = JSON.parse(localStorage.getItem(k)!);
      console.log("Found token", k, tokenObject.body.access_token);
      return tokenObject.body.access_token;
    }
  }
  return "";
};

export const jabsCentralApiPrivateBase = createApi({
  reducerPath: "jabsCentralApiPrivate",
  baseQuery: fetchBaseQuery({
    baseUrl: apiConfig.privateUrl,
    prepareHeaders: async (headers) => {
      headers.set("x-api-key", apiConfig.privateApiKey!);
      const token = await getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: () => ({}),
});

import React from "react";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { Stack } from "@mui/material";

const Wordwrap = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
`;
const PrimaryLight = styled.div`
  background-color: ${(props) => props.theme.palette.primary.light};
  color: ${(props) => props.theme.palette.primary.contrastText};
  padding: 10px;
  margin: 10px;
`;
const PrimaryMain = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  padding: 10px;
  margin: 10px;
`;
const PrimaryDark = styled.div`
  background-color: ${(props) => props.theme.palette.primary.dark};
  color: ${(props) => props.theme.palette.primary.contrastText};
  padding: 10px;
  margin: 10px;
`;

const SecondaryMain = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.secondary.contrastText};
  padding: 10px;
  margin: 10px;
`;

const BackgroundDefault = styled.div`
  background-color: ${(props) => props.theme.palette.background.default};
  color: ${(props) => props.theme.palette.text.primary};
  padding: 10px;
  margin: 10px;
`;
const BackgroundPaper = styled.div`
  background-color: ${(props) => props.theme.palette.background.paper};
  color: ${(props) => props.theme.palette.text.primary};
  padding: 10px;
  margin: 10px;
`;

const ErrorMain = styled.div`
  background-color: ${(props) => props.theme.palette.error.main};
  color: ${(props) => props.theme.palette.error.contrastText};
  padding: 10px;
  margin: 10px;
`;

const InfoMain = styled.div`
  background-color: ${(props) => props.theme.palette.info.main};
  color: ${(props) => props.theme.palette.info.contrastText};
  padding: 10px;
  margin: 10px;
`;

const SuccessMain = styled.div`
  background-color: ${(props) => props.theme.palette.success.main};
  color: ${(props) => props.theme.palette.success.contrastText};
  padding: 10px;
  margin: 10px;
`;

const WarningMain = styled.div`
  background-color: ${(props) => props.theme.palette.warning.main};
  color: ${(props) => props.theme.palette.warning.contrastText};
  padding: 10px;
  margin: 10px;
`;

function ThemeView(props: any) {
  const theme = useTheme();
  return (
    <React.Fragment>
      <BackgroundPaper>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" color="primary">
            Primary
          </Button>
          <Button variant="contained" color="secondary">
            Secondary
          </Button>
          <Button variant="contained" color="info">
            Info
          </Button>
          <Button variant="contained" color="success">
            Success
          </Button>
          <Button variant="contained" color="warning">
            Warning
          </Button>
          <Button variant="contained" color="error">
            Error
          </Button>
        </Stack>
      </BackgroundPaper>
      <PrimaryDark>Primary Dark</PrimaryDark>
      <PrimaryMain>Primary Main</PrimaryMain>
      <PrimaryLight>Primary Light</PrimaryLight>

      <SecondaryMain>Secondary Main</SecondaryMain>

      <BackgroundDefault>Background Default</BackgroundDefault>
      <BackgroundPaper>Background Paper</BackgroundPaper>

      <ErrorMain>Error Main</ErrorMain>

      <InfoMain>Info Main</InfoMain>

      <SuccessMain>Success Main</SuccessMain>

      <WarningMain>Warning Main</WarningMain>

      <BackgroundPaper>
        <h1>Theme Object</h1>
        <Wordwrap>{JSON.stringify(theme, null, 2)}</Wordwrap>
      </BackgroundPaper>
    </React.Fragment>
  );
}

export default ThemeView;

import React from "react";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import Legal from "./layouts/Legal";
import Sidelight from "./layouts/Scratch";

//auth pages
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import AuthGuard from "./components/guards/AuthGuard";

// Page components
import AdminUploadPage from "./pages/pages/AdminUploadPage";
import ApiTestPage from "./pages/pages/ApiTestPage";
import ThemeView from "./pages/pages/ThemeView";
import Blank from "./pages/pages/Blank";

import DashboardPage from "./pages/sidelight/DashboardPage";
import CasesPage from "./pages/sidelight/CasesPage";
import UsersPage from "./pages/sidelight/UsersPage";
import OrganisationsPage from "./pages/sidelight/OrganisationsPage";

import SelectedCasePage from "./pages/sidelight/SelectedCasePage";

import PatientViewPage from "./pages/pages/PatientView";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <Sidelight />
      </AuthGuard>
    ),

    children: [
      {
        path: "/",
        element: <DashboardPage />,
      },
      {
        path: "/dashboard",
        element: <DashboardPage />,
      },
      {
        path: "/cases",
        element: <CasesPage />,
      },
      {
        path: "/case/:id",
        element: <SelectedCasePage />,
      },
      {
        path: "/users",
        element: <UsersPage />,
      },
      {
        path: "/organisations",
        element: <OrganisationsPage />,
      },
    ],
  },
  {
    path: "/admin",
    element: (
      <AuthGuard>
        <Legal />
      </AuthGuard>
    ),
    children: [
      {
        path: "/admin",
        element: <AdminUploadPage />,
      },
      {
        path: "/admin/:id",
        element: <AdminUploadPage />,
      },
      {
        path: "/admin/api-test",
        element: <ApiTestPage />,
      },
      {
        path: "/admin/api-test/:id",
        element: <ApiTestPage />,
      },
      {
        path: "/admin/patient-view",
        element: <PatientViewPage />,
      },
      {
        path: "/admin/patient-view/:id",
        element: <PatientViewPage />,
      },
      {
        path: "/admin/theme-view",
        element: <ThemeView />,
      },
    ],
  },

  {
    path: "/sidelight",
    element: <Sidelight />,
    children: [
      {
        path: "/sidelight/view",
        element: <PatientViewPage />,
      },
      {
        path: "/sidelight/blank",
        element: <Blank />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
];

export default routes;

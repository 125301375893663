import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { jabsCentralApiPrivate } from "../api/jabsCentralApiPrivate";
import { jabsCentralApiPublic } from "../api/jabsCentralApiPublic";
import fileSelectionReducer from "./slices/fileSlice";
import eventReducer from "./slices/event";
import patientSelectionReducer from "./slices/selectedPatientSlice";
import caseSelectionReducer from "./slices/selectedCaseSlice";
import newFileUrlReducer from "./slices/newFileUrlSlice";
import changeMockTimelineReducer from "./slices/mockTimelineSlice";
import selectedEventReducer from "./slices/selectedEventSlice";

const store = configureStore({
  reducer: {
    [jabsCentralApiPrivate.reducerPath]: jabsCentralApiPrivate.reducer,
    [jabsCentralApiPublic.reducerPath]: jabsCentralApiPublic.reducer,
    fileSelection: fileSelectionReducer,
    event: eventReducer,
    patientSelection: patientSelectionReducer,
    caseSelection: caseSelectionReducer,
    newFileUrl: newFileUrlReducer,
    changeMockTimeline: changeMockTimelineReducer,
    selectedEvent: selectedEventReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(jabsCentralApiPrivate.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

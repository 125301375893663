import merge from "deepmerge";
import { orange } from "@mui/material/colors";
import { THEMES } from "../constants";
import { defaultVariant, thinLayerVariant } from "./variantThemes";

const sidelightGrey = {
  "50": "#F2F3F8",
  "100": "#E5E9F1",
  "200": "#CDD3DF",
  "300": "#B1B8C8",
  "400": "#98A1B3",
  "500": "#808A9D",
  "600": "#697286",
  "700": "#525B6F",
  "800": "#3D4557",
  "900": "#272E3F",
};

const sidelightVariant = merge(defaultVariant, {
  name: THEMES.SIDELIGHT,
  palette: {
    primary: {
      main: "#008794",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#90F0F9",
      contrastText: "#003f47",
    },
    info: {
      main: "#3462B3",
      contrastText: "#FFF",
    },
    success: {
      main: "#0f9259",
      contrastText: "#FFF",
    },
    warning: {
      main: "#ffb966",
      contrastText: "#381d00",
    },
    error: {
      main: "#ce4040",
      contrastText: "#FFF",
    },
    grey: {
      "50": sidelightGrey[50],
      "100": sidelightGrey[100],
      "200": sidelightGrey[200],
      "300": sidelightGrey[300],
      "400": sidelightGrey[400],
      "500": sidelightGrey[500],
      "600": sidelightGrey[600],
      "700": sidelightGrey[700],
      "800": sidelightGrey[800],
      "900": sidelightGrey[900],
      A100: sidelightGrey[100],
      A200: sidelightGrey[200],
      A400: sidelightGrey[400],
      A700: sidelightGrey[700],
    },
    accent: {
      main: orange[600],
    },
  },
  header: {
    indicator: {
      background: sidelightGrey[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: sidelightGrey[800],
    header: {
      color: "#FFF",
      background: sidelightGrey[900],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: sidelightGrey[900],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const variants: Array<VariantType> = [thinLayerVariant, sidelightVariant];

export default variants;

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
  };
  header: ColorBgType & {
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
    footer: ColorBgType & {
      online: {
        background: string;
      };
    };
    badge: ColorBgType;
  };
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
};
type ColorBgType = {
  color: string;
  background: string;
};

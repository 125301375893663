// React imports
import React from "react";
import { Helmet } from "react-helmet-async";

// Material-UI imports
import { Box } from "@mui/material";
import styled from "@emotion/styled";

// Local imports
import SidelightPageBar from "../../components/navbar/appBar/SidelightPageBar";
import CaseTableCard from "../../components/caseList/CaseTableCard";
import CaseSelector from "../../components/caseList/CaseSelector";

const PageContentContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 25px;
`;

const CasesPage: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet title="Cases" />
      <SidelightPageBar title="Cases" rightTools={<CaseSelector />} />
      <PageContentContainer>
        <CaseTableCard />
      </PageContentContainer>
    </React.Fragment>
  );
};

export default CasesPage;

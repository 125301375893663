// React imports
import React from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

// redux imports
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { setSelectedCase } from "../../redux/slices/selectedCaseSlice";

// Material-UI imports
import { Box } from "@mui/material";
import styled from "@emotion/styled";

// Local imports
import SidelightPageBar from "../../components/navbar/appBar/SidelightPageBar";

import CaseSelector from "../../components/caseList/CaseSelector";

//data imports
import caseListData from "../../mockData/sidelight/caseData";

const PageContentContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 25px;
`;

const SelectedCasePage: React.FC = () => {
  const dispatch = useDispatch();
  const selectedCaseRedux = String(
    useSelector((state: RootState) => state.caseSelection.selectedItem)
  );
  const selectedCase = useParams<{ id: string }>().id;
  if (selectedCase !== selectedCaseRedux && selectedCase) {
    dispatch(setSelectedCase(selectedCase));
  }
  const selectedCaseData = caseListData.find((c) => c.case_id === selectedCase);

  return (
    <React.Fragment>
      <Helmet title={selectedCaseData?.case_title} />
      <SidelightPageBar
        title={selectedCaseData?.case_title}
        breakpointRight="md"
        rightTools={<CaseSelector />}
      />
      <PageContentContainer></PageContentContainer>
    </React.Fragment>
  );
};

export default SelectedCasePage;

import { THEMES } from "../constants";
import { grey, green, orange, deepOrange } from "@mui/material/colors";
import merge from "deepmerge";

const customBlue = {
  50: "#E8F2FD",
  100: "#A1CDF7",
  200: "#69AFF2",
  300: "#489DEF",
  400: "#278CEC",
  500: "#0B75DA",
  600: "#0A68C2",
  700: "#005EB8",
  800: "#004C94",
  900: "#003970 ",
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: customBlue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FFA000",
      contrastText: "#000",
    },
    background: {
      default: "#F2F3F8",
      paper: "#FFF",
    },
    accent: {
      main: orange[600],
    },
    news: {
      zero: "#FFFFFF",
      one: "#FFF0AB",
      two: "#FDC88A",
      three: "#F59680",
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#233044",
    header: {
      color: grey[200],
      background: "#233044",
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#1E2A38",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customBlue[500],
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          stroke: "currentColor",
        },
      },
    },
  },
};

const thinLayerIndigo = {
  "50": "#ECEEF9",
  "100": "#C9CEEE",
  "200": "#96A1DE",
  "300": "#7785D5",
  "400": "#6374CF",
  "500": "#5061BF",
  "600": "#4A59A6",
  "700": "#3C4A8B",
  "800": "#28356C",
  "900": "#14214E",
};

const thinLayerVariant = merge(defaultVariant, {
  name: THEMES.THINLAYER,
  palette: {
    primary: {
      main: thinLayerIndigo[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FFA000",
      contrastText: "#000",
    },
    accent: {
      main: orange[600],
    },
    warning: {
      main: deepOrange[900],
    },
  },
  header: {
    indicator: {
      background: thinLayerIndigo[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: thinLayerIndigo[700],
    header: {
      color: "#FFF",
      background: thinLayerIndigo[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: thinLayerIndigo[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

export { defaultVariant };
export { thinLayerVariant };

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectionCaseId {
  selectedItem: string | null;
}

const initialState: SelectionCaseId = {
  selectedItem: null,
};

const selectedCaseSlice = createSlice({
  name: "caseSelection",
  initialState,
  reducers: {
    setSelectedCase(state, action: PayloadAction<string>) {
      state.selectedItem = action.payload;
    },
    clearSelectedCase(state) {
      state.selectedItem = null;
    },
  },
});

export const { setSelectedCase, clearSelectedCase } = selectedCaseSlice.actions;
export default selectedCaseSlice.reducer;

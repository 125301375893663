// React imports
import React from "react";
import { Helmet } from "react-helmet-async";

// Local imports
import SidelightPageBar from "../../components/navbar/appBar/SidelightPageBar";
import CaseSelector from "../../components/caseList/CaseSelector";

const UsersPage: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet title="Users" />
      <SidelightPageBar
        title="Users"
        breakpointRight="sm"
        rightTools={<CaseSelector />}
      />
    </React.Fragment>
  );
};

export default UsersPage;
